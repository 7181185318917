class Commen {
  private arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

  // 获取随机数
  getId(length = 32): string {
    let num = '';
    for (let i = 0; i < length; i++) {
      if (i % 4 === 0 && i !== 0) {
        num += "-" + this.arr[parseInt(String(Math.random() * this.arr.length))]
      } else {
        num += this.arr[parseInt(String(Math.random() * this.arr.length))];
      }
    }
    return num;
  }

  // 改变数组顺序
  swapArray(arr, index1, index2): Array<any> {
    arr[index1] = arr.splice(index2, 1, arr[index1])[0];
    return arr;
  }

  dataURLtoFile(dataurl, filename) {//将base64转换为文件
    const arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, {type: mime})
  }
}

const commen = new Commen();
export default commen;

